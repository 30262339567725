/**
 * CAdESCOM hash algorithms constants.
 *
 * @module constants/HashAlgorithms
 */

/**
 * SHA1.
 *
 * @type {number}
 */
export const CADESCOM_HASH_ALGORITHM_SHA1 = 0;

/**
 * MD2.
 *
 * @type {number}
 */
export const CADESCOM_HASH_ALGORITHM_MD2 = 1;

/**
 * MD4.
 *
 * @type {number}
 */
export const CADESCOM_HASH_ALGORITHM_MD4 = 2;

/**
 * MD5.
 *
 * @type {number}
 */
export const CADESCOM_HASH_ALGORITHM_MD5 = 3;

/**
 * SHA1 with a key length of 256 bits.
 *
 * @type {number}
 */
export const CADESCOM_HASH_ALGORITHM_SHA_256 = 4;

/**
 * SHA1 with a key length of 384 bits.
 *
 * @type {number}
 */
export const CADESCOM_HASH_ALGORITHM_SHA_384 = 5;

/**
 * SHA1 with a key length of 512 bits.
 *
 * @type {number}
 */
export const CADESCOM_HASH_ALGORITHM_SHA_512 = 6;

/**
 * ГОСТ Р 34.11-94.
 *
 * @type {number}
 */
export const CADESCOM_HASH_ALGORITHM_CP_GOST_3411 = 100;

/**
 * ГОСТ Р 34.11-2012. (256 bits).
 *
 * @type {number}
 */
export const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256 = 101;

/**
 * ГОСТ Р 34.11-2012. (512 bits).
 *
 * @type {number}
 */
export const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512 = 102;

/**
 * ГОСТ Р 34.11-94 HMAC.
 *
 * @type {number}
 */
export const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_HMAC = 110;

/**
 * ГОСТ Р 34.11-2012 HMAC. (256 bits).
 *
 * @type {number}
 */
export const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256_HMAC = 111;

/**
 * ГОСТ Р 34.11-2012 HMAC. (512 bits).
 *
 * @type {number}
 */
export const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512_HMAC = 112;

export default {
  CADESCOM_HASH_ALGORITHM_SHA1,
  CADESCOM_HASH_ALGORITHM_MD2,
  CADESCOM_HASH_ALGORITHM_MD4,
  CADESCOM_HASH_ALGORITHM_MD5,
  CADESCOM_HASH_ALGORITHM_SHA_256,
  CADESCOM_HASH_ALGORITHM_SHA_384,
  CADESCOM_HASH_ALGORITHM_SHA_512,
  CADESCOM_HASH_ALGORITHM_CP_GOST_3411,
  CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256,
  CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512,
  CADESCOM_HASH_ALGORITHM_CP_GOST_3411_HMAC,
  CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256_HMAC,
  CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512_HMAC
};
