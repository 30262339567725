/**
 * CAPICOM certificate chain modes.
 *
 * @module constants/CertChainModes
 */

/**
 * Include the whole certificates chain except root.
 * @type {number}
 */
export const CAPICOM_CERTIFICATE_INCLUDE_CHAIN_EXCEPT_ROOT = 0;

/**
 * Include the whole certificates chain.
 *
 * @type {number}
 */
export const CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN = 1;

/**
 * Include only the end entity.
 *
 * @type {number}
 */
export const CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY = 2;

export default {
  CAPICOM_CERTIFICATE_INCLUDE_CHAIN_EXCEPT_ROOT,
  CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN,
  CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY
};
