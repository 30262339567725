var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-alert", { attrs: { value: !_vm.loaded, type: "error" } }, [
        _vm._v("\n    Плагин не загружен\n  ")
      ]),
      _c("v-alert", { attrs: { value: _vm.formattedError, type: "error" } }, [
        _vm._v("\n    " + _vm._s(_vm.formattedError || " ") + "\n  ")
      ]),
      _vm.sig !== ""
        ? _c(
            "v-alert",
            { attrs: { value: true, type: "success", color: "#4ad85e" } },
            [_vm._v("\n    Файл подписан\n  ")]
          )
        : _vm._e(),
      _vm.loaded
        ? _c(
            "div",
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-radio-group",
                        {
                          attrs: { row: "" },
                          model: {
                            value: _vm.sourceType,
                            callback: function($$v) {
                              _vm.sourceType = $$v
                            },
                            expression: "sourceType"
                          }
                        },
                        [
                          _c("v-radio", { attrs: { label: "Файл", value: 2 } }),
                          _c("v-radio", {
                            attrs: { label: "Текст", value: 0 }
                          }),
                          _c("v-radio", { attrs: { label: "Хеш", value: 1 } })
                        ],
                        1
                      ),
                      _vm.sourceType === 2
                        ? _c(
                            "div",
                            [
                              _c("upload-btn", {
                                staticClass: "px-0",
                                attrs: {
                                  labelClass: "upload-file-btn",
                                  title: "Выберите файл",
                                  depressed: ""
                                },
                                on: { "file-update": _vm.updateFile }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("upload-btn", {
                        staticClass: "px-0",
                        attrs: {
                          labelClass: "upload-file-btn",
                          title: "Выберите файл подписи",
                          depressed: ""
                        },
                        on: { "file-update": _vm.signatureFileChanged }
                      }),
                      _vm.sourceType < 2
                        ? _c(
                            "div",
                            [
                              _vm.sourceType < 2
                                ? _c("v-text-field", {
                                    attrs: { label: "Имя документа" },
                                    model: {
                                      value: _vm.documentName,
                                      callback: function($$v) {
                                        _vm.documentName = $$v
                                      },
                                      expression: "documentName"
                                    }
                                  })
                                : _vm._e(),
                              _vm.sourceType < 2
                                ? _c("v-textarea", {
                                    attrs: { label: "Исходный документ" },
                                    model: {
                                      value: _vm.text,
                                      callback: function($$v) {
                                        _vm.text = $$v
                                      },
                                      expression: "text"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "my-3", attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.certs,
                          "item-text": _vm.certName,
                          "return-object": "",
                          label: "Сертификат:"
                        },
                        model: {
                          value: _vm.certificate,
                          callback: function($$v) {
                            _vm.certificate = $$v
                          },
                          expression: "certificate"
                        }
                      }),
                      _vm.certificate
                        ? _c("div", { class: _vm.certInfoClass }, [
                            _c("label", [_vm._v("Владелец:")]),
                            _vm._v(" " + _vm._s(_vm.certInfo.owner)),
                            _c("br"),
                            _c("label", [_vm._v("Издатель:")]),
                            _vm._v(" " + _vm._s(_vm.certInfo.issuer)),
                            _c("br"),
                            _c("label", [_vm._v("Выдан:")]),
                            _vm._v(" " + _vm._s(_vm.certInfo.since)),
                            _c("br"),
                            _c("label", [_vm._v("Действителен до:")]),
                            _vm._v(" " + _vm._s(_vm.certInfo.till)),
                            _c("br"),
                            _c("label", [_vm._v("Криптопровайдер:")]),
                            _vm._v(" " + _vm._s(_vm.certInfo.provider)),
                            _c("br"),
                            _c("label", [_vm._v("Алгоритм ключа:")]),
                            _vm._v(" " + _vm._s(_vm.certInfo.algorithm)),
                            _c("br"),
                            _c("label", [_vm._v("Статус:")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.tr(
                                    "certStatus[" + _vm.certInfo.status + "]"
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                { staticClass: "mt-3" },
                [
                  _c(
                    "v-expansion-panel-content",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "actions",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("settings_applications")])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "header",
                            fn: function() {
                              return [_c("div", [_vm._v("Параметры подписи")])]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3179469719
                      )
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.hashAlgorithms,
                                  disabled: _vm.sourceType < 1,
                                  label: "Алгоритм хэш-суммы:"
                                },
                                model: {
                                  value: _vm.hashAlgorithm,
                                  callback: function($$v) {
                                    _vm.hashAlgorithm = $$v
                                  },
                                  expression: "hashAlgorithm"
                                }
                              }),
                              _c("v-textarea", {
                                attrs: { label: "Описание документа" },
                                model: {
                                  value: _vm.documentDescription,
                                  callback: function($$v) {
                                    _vm.documentDescription = $$v
                                  },
                                  expression: "documentDescription"
                                }
                              }),
                              _c(
                                "v-radio-group",
                                {
                                  model: {
                                    value: _vm.detached,
                                    callback: function($$v) {
                                      _vm.detached = $$v
                                    },
                                    expression: "detached"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      label: "Прикрепленная",
                                      value: false
                                    }
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      label: "Открепленная",
                                      value: true
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-checkbox", {
                                attrs: { label: "Время подписания" },
                                model: {
                                  value: _vm.signingTimeAttr,
                                  callback: function($$v) {
                                    _vm.signingTimeAttr = $$v
                                  },
                                  expression: "signingTimeAttr"
                                }
                              }),
                              _c("v-checkbox", {
                                attrs: { label: "Является документом XML" },
                                model: {
                                  value: _vm.isXML,
                                  callback: function($$v) {
                                    _vm.isXML = $$v
                                  },
                                  expression: "isXML"
                                }
                              }),
                              _c("v-checkbox", {
                                attrs: {
                                  disabled: _vm.isXML || !_vm.detached,
                                  label: "Кодировка Base64"
                                },
                                model: {
                                  value: _vm.useBase64,
                                  callback: function($$v) {
                                    _vm.useBase64 = $$v
                                  },
                                  expression: "useBase64"
                                }
                              }),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.certChainModes,
                                  label:
                                    "Тип цепочки сертификатов, прикрепляемой к подписи:"
                                },
                                model: {
                                  value: _vm.certChainMode,
                                  callback: function($$v) {
                                    _vm.certChainMode = $$v
                                  },
                                  expression: "certChainMode"
                                }
                              }),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.signatureTypes,
                                  disabled: !_vm.isXML,
                                  label: "Тип подписи:"
                                },
                                model: {
                                  value: _vm.signatureType,
                                  callback: function($$v) {
                                    _vm.signatureType = $$v
                                  },
                                  expression: "signatureType"
                                }
                              }),
                              _c("v-checkbox", {
                                attrs: { label: "Декодировать в DER" },
                                model: {
                                  value: _vm.decodeToDER,
                                  callback: function($$v) {
                                    _vm.decodeToDER = $$v
                                  },
                                  expression: "decodeToDER"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.sig === ""
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mx-3 my-3",
                      attrs: {
                        loading: _vm.loading === true,
                        color: "#4ad85e",
                        dark: "",
                        depressed: ""
                      },
                      on: { click: _vm.sign }
                    },
                    [_vm._v("\n      Подписать\n    ")]
                  )
                : _vm._e(),
              _vm.sig !== ""
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mx-0 my-3",
                      attrs: { color: "#4ad85e", dark: "", depressed: "" },
                      on: { click: _vm.save }
                    },
                    [_vm._v("Сохранить файл подписи")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }