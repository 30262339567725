import { render, staticRenderFns } from "./SignificatorAdd.vue?vue&type=template&id=6edbd592&"
import script from "./SignificatorAdd.vue?vue&type=script&lang=js&"
export * from "./SignificatorAdd.vue?vue&type=script&lang=js&"
import style0 from "./SignificatorAdd.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('6edbd592', component.options)
    } else {
      api.reload('6edbd592', component.options)
    }
    module.hot.accept("./SignificatorAdd.vue?vue&type=template&id=6edbd592&", function () {
      api.rerender('6edbd592', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SignificatorAdd.vue"
export default component.exports