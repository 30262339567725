var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("v-toolbar", { attrs: { color: "#25459f" } }),
      _c("v-content", [_c("v-container", [_c("Menu")], 1)], 1),
      _c("v-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }