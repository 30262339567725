/**
 * Signature types constants.
 *
 * @module constants/SignatureTypes
 */

/**
 * Default signature type (CAdES-X Long Type 1).
 *
 * @type {number}
 */
export const CADESCOM_CADES_DEFAULT = 0;

/**
 * CAdES BES signature type.
 *
 * @type {number}
 */
export const CADESCOM_CADES_BES = 1;

/**
 * CAdES-T signature type.
 *
 * @type {number}
 */
export const CADESCOM_CADES_T = 0x5;

/**
 * CAdES-X Long Type 1 signature type.
 *
 * @type {number}
 */
export const CADESCOM_CADES_X_LONG_TYPE_1 = 0x5d;

/**
 * PKCS7 signature type.
 *
 * @type {number}
 */
export const CADESCOM_PKCS7_TYPE = 0xffff;

export default {
  CADESCOM_CADES_DEFAULT,
  CADESCOM_CADES_BES,
  CADESCOM_CADES_T,
  CADESCOM_CADES_X_LONG_TYPE_1,
  CADESCOM_PKCS7_TYPE
};
