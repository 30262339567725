/**
 * This module provides a simple translation function factory.
 *
 * @module utils/translate
 */

/**
 * Translates a query.
 *
 * @param {string} query
 * @returns {string}
 */
function translate(query) {
  let result;
  eval('result = this.' + query);

  return result;
}

/**
 * Generates a translation function.
 *
 * @param {object} data - translations object
 * @returns {Function} - translator
 */
export function translateFactory(data) {
  return translate.bind(data);
}

export default translateFactory;
