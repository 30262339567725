var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tabs",
        {
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c("v-tab", [_vm._v("\n      Подписать документ\n    ")]),
          _c(
            "v-tab-item",
            [_c("v-card", { attrs: { flat: "" } }, [_c("Significator")], 1)],
            1
          ),
          _c("v-tab", [_vm._v("\n      Добавить подпись\n    ")]),
          _c(
            "v-tab-item",
            [_c("v-card", { attrs: { flat: "" } }, [_c("SignificatorAdd")], 1)],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }