<template>
  <v-app>
    <v-toolbar color="#25459f"></v-toolbar>
    <v-content>
      <v-container>
        <Menu />
      </v-container>
    </v-content>
    <v-footer></v-footer>
  </v-app>
</template>

<script>
import Menu from './components/Menu.vue';

export default {
  name: 'app',
  components: {
    Menu
  }
};
</script>

<style>
#app {
  font-family: 'Open Sans', sans-serif;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
