/**
 * Extracts a section form a certificate name.
 *
 * @param {string} from
 * @param {string} what
 * @returns {string}
 */
export function extract(from, what) {
  let certName = '';
  const begin = from.indexOf(what);

  if (begin >= 0) {
    const end = from.indexOf(', ', begin);
    certName = end < 0 ? from.substr(begin) : from.substr(begin, end - begin);
  }

  return certName;
}

/**
 * Formats a date.
 *
 * @param {Date|string} paramDate
 * @returns {string}
 */
export function getCertDate(paramDate) {
  const certDate = new Date(paramDate);

  return (
    certDate
      .getUTCDate()
      .toString()
      .padStart(2, '0') +
    '.' +
    (certDate.getUTCMonth() + 1).toString().padStart(2, '0') +
    '.' +
    certDate.getFullYear() +
    ' ' +
    certDate
      .getUTCHours()
      .toString()
      .padStart(2, '0') +
    ':' +
    certDate
      .getUTCMinutes()
      .toString()
      .padStart(2, '0') +
    ':' +
    certDate
      .getUTCSeconds()
      .toString()
      .padStart(2, '0')
  );
}

/**
 * Extracts a certificate name.
 *
 * @param {string} certSubjectName
 * @returns {string}
 */
export function getCertName(certSubjectName) {
  return extract(certSubjectName, 'CN=');
}

/**
 * Extracts an isser.
 *
 * @param {string} certIssuerName
 * @returns {string}
 */
export function getIssuer(certIssuerName) {
  return extract(certIssuerName, 'CN=');
}

/**
 * Generates certificate info string.
 *
 * @param {string} certSubjectName -certificate subject name
 * @param {Date} certFromDate - key date
 * @param {Function} translator - translator (receives 'issueDate' string if specified)
 * @param {Function} formatter - custom date formatter (if formatter isn't specified `getCertDate` will be used)
 * @returns {string}
 */
export function getCertInfoString(certSubjectName, certFromDate, translator = null, formatter = null) {
  return (
    extract(certSubjectName, 'CN=') +
    '; ' +
    (translator ? translator('issueDate') : 'Issue date') +
    ': ' +
    (formatter ? formatter(certFromDate) : getCertDate(certFromDate))
  );
}
