/**
 * CAPICOM and CAdESCOM stores.
 *
 * @module constants/Stores
 */

/**
 * CAPICOM local machine store.
 *
 * @type {number}
 */
export const CAPICOM_LOCAL_MACHINE_STORE = 1;

/**
 * CAPICOM current user store.
 *
 * @type {number}
 */
export const CAPICOM_CURRENT_USER_STORE = 2;

/**
 * Personal user store.
 *
 * @type {string}
 */
export const CAPICOM_MY_STORE = 'My';

/**
 * CAdESCOM local machine store.
 *
 * @type {number}
 */
export const CADESCOM_LOCAL_MACHINE_STORE = 1;

/**
 * CAdESCOM current user store.
 *
 * @type {number}
 */
export const CADESCOM_CURRENT_USER_STORE = 2;

/**
 * Certificate store in private key containers.
 *
 * All certificates from the containers of private keys that are available
 * in the system at the time of opening will go to this Store.
 *
 * @type {number}
 */
export const CADESCOM_CONTAINER_STORE = 100;

export default {
  CAPICOM_LOCAL_MACHINE_STORE,
  CAPICOM_CURRENT_USER_STORE,
  CAPICOM_MY_STORE,
  CADESCOM_LOCAL_MACHINE_STORE,
  CADESCOM_CURRENT_USER_STORE,
  CADESCOM_CONTAINER_STORE
};
