/**
 * This module provides a CAdES certificates list loader.
 *
 * @module utils/CertsLoader
 */
import { getCertInfoString, getCertName, getIssuer } from '@/utils/certificate-adjuster';
import CadesError from '@/utils/cades-error';

export const certificateStatus = {
  unknown: 0,
  notValidYet: 1,
  expired: 2,
  noBindingToPrivateKey: 3,
  chainValidationError: 4,
  active: 5
};

/**
 * Fills cert item fields.
 *
 * This function loads fields and a cert name.
 *
 * @async
 *
 * @param {CadesPluginWrapper} plugin - CAdES plugin wrapper instance
 * @param {object} cert - certificate instance
 * @param {Function} tr - translator
 * @returns {{text: string, cn: string, thumbprint: string, cert: object}} - item
 */
async function fillItem(plugin, cert, tr) {
  const item = {};
  let validFromDate, subjectName, thumbprint;

  try {
    validFromDate = new Date(await cert.ValidFromDate);
  } catch (e) {
    throw new CadesError(6, plugin.getLastError(e));
  }

  try {
    subjectName = await cert.SubjectName;
  } catch (e) {
    throw new CadesError(7, plugin.getLastError(e));
  }

  try {
    thumbprint = await cert.Thumbprint;
  } catch (e) {
    throw new CadesError(8, plugin.getLastError(e));
  }

  item.text = getCertInfoString(subjectName, new Date(validFromDate), tr);
  item.cn = getCertName(subjectName).replace(/^CN=/, '');
  item.thumbprint = thumbprint;
  item.cert = cert;

  return item;
}

/**
 * Loads sertificates from storages.
 *
 * This function returns an array of certificates with its unique IDs and names or throws an instance of `CadesError`.
 *
 * @async
 *
 * @example
 * [{
 *   text: 'CN=Test Certificate; Issue date: 11.06.2019 09:09:13',
 *   cn: 'Test Certificate',
 *   thumbprint: '44F1C4C8C756BD2FB122DAE482352BFE0EBE369A',
 *   cert: Object
 * }]
 *
 * @param {CadesPluginWrapper} plugin - CAdES plugin instance
 * @param {Function} tr - translator
 * @returns {Array<object>} - certificates list
 */
export async function loadCerts(plugin, tr) {
  const result = [];
  let storeExists = true;
  let oStore;

  try {
    oStore = await plugin.createObject('CAdESCOM.Store');
    if (!oStore) throw new CadesError(2);

    await oStore.Open();
  } catch (e) {
    storeExists = false;
  }

  let certCnt;
  let certs;

  if (storeExists) {
    try {
      certs = await oStore.Certificates;
    } catch (e) {
      throw new CadesError(3, plugin.getLastError(e));
    }

    try {
      certCnt = await certs.Count;
    } catch (e) {
      throw new CadesError(4, plugin.getLastError(e));
    }

    for (let i = 1; i <= certCnt; i++) {
      let cert;

      try {
        cert = await certs.Item(i);
      } catch (e) {
        throw new CadesError(5, plugin.getLastError(e));
      }

      result.push(await fillItem(plugin, cert, tr));
    }

    await oStore.Close();
  }

  // For plugin version 2.0.13292+: we can load certificates from locked and not installed keys
  // This part of the code will not throw an error in any way.
  try {
    await oStore.Open(plugin.CADESCOM_CONTAINER_STORE);
    certs = await oStore.Certificates;
    certCnt = await certs.Count;

    for (let i = 1; i <= certCnt; i++) {
      const cert = await certs.Item(i);
      let found = false;

      for (let j = 0; j < result.length; j++) {
        if (result[j].thumbprint === (await cert.Thumbprint)) {
          found = true;
          break;
        }
      }

      if (!found) result.push(await fillItem(plugin, cert));
    }

    await oStore.Close();
  } catch (e) {
    // nope
  }

  return result;
}

/**
 * Loads a certificate info.
 *
 * @async
 *
 * @param {object} certificate
 * @returns {object}
 */
export async function getCertInfo(certificate) {
  const validToDate = new Date(await certificate.ValidToDate);
  const validSinceDate = new Date(await certificate.ValidFromDate);

  let valid = false;

  // ignore failed validation for certificates with unknown algorithms.
  try {
    const validator = await certificate.IsValid();
    valid = await validator.Result;
  } catch (e) {
    // nope
  }

  const hasPrivateKey = await certificate.HasPrivateKey();
  const now = new Date();

  const owner = getCertName(await certificate.SubjectName);
  const issuer = getIssuer(await certificate.IssuerName);
  const since = validSinceDate;
  const till = validToDate;

  const pubKey = await certificate.PublicKey();
  const algo = await pubKey.Algorithm;
  const algorithm = await algo.FriendlyName;

  let provider = '';

  if (hasPrivateKey) {
    const privateKey = await certificate.PrivateKey;

    provider = await privateKey.ProviderName;
  }

  let status = certificateStatus.unknown;

  if (now < validSinceDate) status = certificateStatus.notValidYet;
  else if (now > validToDate) status = certificateStatus.expired;
  else if (!hasPrivateKey) status = certificateStatus.noBindingToPrivateKey;
  else if (!valid) status = certificateStatus.chainValidationError;
  else status = certificateStatus.active;

  return { owner, issuer, since, till, algorithm, provider, status };
}

export default {
  certificateStatus,
  loadCerts,
  getCertInfo
};
