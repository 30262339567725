/**
 * CAdESCOM content encodings constants.
 *
 * @module constants/ContentEncodings
 */

/**
 * Convert data to UCS-2 little endian.
 *
 * @type {number}
 */
export const CADESCOM_STRING_TO_UCS2LE = 0;

/**
 * Convert data from Base64 to binary.
 *
 * @type {number}
 */
export const CADESCOM_BASE64_TO_BINARY = 1;

export default {
  CADESCOM_STRING_TO_UCS2LE,
  CADESCOM_BASE64_TO_BINARY
};
