/**
 * @module utils/Signification
 */
import CadesError from './cades-error';
import { CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED } from './constants/other';
import { CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME } from './constants/search-types';
import {
  CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256,
  CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512,
  CADESCOM_HASH_ALGORITHM_CP_GOST_3411
} from './constants/hash-algorithms';
import { CADESCOM_ENCODE_BASE64 } from './constants/encodings';
import { CAPICOM_CURRENT_USER_STORE, CAPICOM_MY_STORE } from './constants/stores';
import { CADESCOM_BASE64_TO_BINARY } from './constants/content-encodings';
import { CADESCOM_XML_SIGNATURE_TYPE_TEMPLATE } from './constants/xml-signature-types';
import {
  XmlDsigGost3410Url,
  XmlDsigGost3411Url,
  XmlDsigGost34102012Url256,
  XmlDsigGost34112012Url256,
  XmlDsigGost34102012Url512,
  XmlDsigGost34112012Url512
} from './constants/xml-dsig-algorithms';
import { CADESCOM_CADES_BES } from './constants/signature-types';
import { CAPICOM_CERTIFICATE_INCLUDE_CHAIN_EXCEPT_ROOT } from './constants/cert-chain-modes';
import {
  CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME,
  CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME,
  CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_DESCRIPTION
} from './constants/authenticated-attributes';

/**
 * Default sign options object.
 *
 * @type {object}
 */
export const defaultOptions = {
  /**
   * Data content encoding is Base64 (doesn't work with `xml`).
   *
   * @type {boolean}
   */
  base64: false,

  /**
   * Document type is XML.
   *
   * @type {boolean}
   */
  xml: false,

  /**
   * Signature type {@link http://cpdn.cryptopro.ru/content/cades/namespace_c_ad_e_s_c_o_m_bc786984157586e73c5fca967d9da4ef_1bc786984157586e73c5fca967d9da4ef.html}
   *
   * @type {number}
   */
  signatureType: 0,

  /**
   * Generate a detached signature.
   *
   * @type {boolean}
   */
  detached: true,

  /**
   * Sign a hash instead of a document.
   *
   * @type {boolean}
   */
  hash: false,

  /**
   * XPath value (only for XML documents with `Template` signature type).
   *
   * @type {string}
   */
  xpath: '',

  /**
   * Expected sign algorithm.
   *
   * @type {string}
   */
  signAlgorithm: '',

  /**
   * Hash algorithm.
   *
   * @type {number}
   */
  hashAlgorithm: -1,

  /**
   * Certificates chain mode.
   *
   * @type {number}
   */
  certChainMode: CAPICOM_CERTIFICATE_INCLUDE_CHAIN_EXCEPT_ROOT,

  /**
   * Previous signature.
   *
   * @type {string}
   */
  previousSignature: '',

  /**
   * Append date.
   *
   * @type {boolean}
   */
  appendDate: false,

  /**
   * Append document name.
   *
   * @type {string}
   */
  documentName: '',

  /**
   * Append document description.
   *
   * @type {string}
   */
  documentDescription: ''
};

/**
 * Returns certificate algorithm id.
 *
 * @async
 *
 * @param {CadesPluginWrapper} plugin
 * @param {object} certificate
 * @returns {string}
 */
export async function getCertificateAlgorithm(plugin, certificate) {
  let algoId;

  try {
    const pubKey = await certificate.PublicKey();
    const algo = await pubKey.Algorithm;
    algoId = await algo.Value;
  } catch (e) {
    throw new CadesError(19, plugin.getLastError(e));
  }

  return algoId;
}

/**
 * Calculates a hash algorithm for a certificate.
 *
 * @async
 *
 * @param {CadesPluginWrapper} plugin
 * @param {object} certificate
 * @returns {number} - hash algorithm name
 */
export async function getHashAlgorithm(plugin, certificate) {
  const algoId = await getCertificateAlgorithm(plugin, certificate);
  if (algoId === '1.2.643.7.1.1.1.1') {
    // ГОСТ Р 34.10-2012 with a 256-bit key
    return CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256;
  } else if (algoId === '1.2.643.7.1.1.1.2') {
    // ГОСТ Р 34.10-2012 with a 512-bit key
    return CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512;
  } else if (algoId === '1.2.643.2.2.19') {
    // ГОСТ Р 34.10-2001
    return CADESCOM_HASH_ALGORITHM_CP_GOST_3411;
  } else throw new CadesError(14, 'Expected: ГОСТ Р 34.10-2012, ГОСТ Р 34.10-2001');
}

/**
 * Creates and returns a signature without verification.
 *
 * @async
 *
 * @param {CadesPluginWrapper} plugin
 * @param {object} certificate - certificate object
 * @param {string|object} dataToSign
 * @param {object} options - additional options
 * @param {boolean} options.base64 - data content encoding is Base64
 * @param {boolean} options.xml - use xml object
 * @param {boolean} options.hash - sign hash instead of a document
 * @param {string} options.xpath - custom template xpath
 * @param {boolean} options.detached - generate a detached signature
 * @param {number} options.signatureType - xml signature type ({@link http://cpdn.cryptopro.ru/content/cades/namespace_c_ad_e_s_c_o_m_bc786984157586e73c5fca967d9da4ef_1bc786984157586e73c5fca967d9da4ef.html})
 * @param {string} options.signAlgorithm - expected sign algorithm
 * @param {number} options.hashAlgorithm - used hash algorithm
 * @param {number} options.certChainMode - certificate chain mode
 * @param {string} options.previousSignature - previous signature
 * @param {boolean} options.appendDate - append sign date attribute
 * @param {string} options.documentName - document name attribute
 * @param {string} options.documentDescription - document description attribute
 * @returns {string} - signature
 */
export async function createSign(plugin, certificate, dataToSign, options) {
  options = Object.assign({}, defaultOptions, options);
  let signedMessage = null;
  let data;

  if (options.hash && typeof dataToSign === 'string') {
    data = await initHashData(plugin, dataToSign, options.hashAlgorithm, options.base64);
  } else data = dataToSign;

  try {
    const signer = await plugin.createObject('CAdESCOM.CPSigner');

    const attributes = await signer.AuthenticatedAttributes2;

    if (options.appendDate) {
      const signingTime = await plugin.createObject('CAdESCOM.CPAttribute');
      const now = new Date();

      await plugin.setProp(signingTime, 'Name', CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME);
      await plugin.setProp(signingTime, 'Value', now);
      await attributes.Add(signingTime);
    }

    if (options.documentName) {
      const documentName = await plugin.createObject('CAdESCOM.CPAttribute');

      await plugin.setProp(documentName, 'Name', CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME);
      await plugin.setProp(documentName, 'Value', options.documentName);
      await attributes.Add(documentName);
    }

    if (options.documentDescription) {
      const documentDescription = await plugin.createObject('CAdESCOM.CPAttribute');

      await plugin.setProp(documentDescription, 'Name', CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_DESCRIPTION);
      await plugin.setProp(documentDescription, 'Value', options.documentDescription);
      await attributes.Add(documentDescription);
    }

    await plugin.setProp(signer, 'Options', options.certChainMode);
    await plugin.setProp(signer, 'Certificate', certificate);

    const signedData = await plugin.createObject(options.xml ? 'CAdESCOM.SignedXML' : 'CAdESCOM.CadesSignedData');

    let signMethod = '';
    let digestMethod = '';

    const algoId = await getCertificateAlgorithm(plugin, certificate);

    if (options.signAlgorithm !== '' && algoId !== options.signAlgorithm) {
      throw new CadesError(14, 'Expected: ' + options.signAlgorithm);
    } else if (algoId === '1.2.643.7.1.1.1.1') {
      // sign algorithm ГОСТ Р 34.10-2012/34.11-2012 with a 256-bit key
      signMethod = XmlDsigGost34102012Url256;
      digestMethod = XmlDsigGost34112012Url256;
    } else if (algoId === '1.2.643.7.1.1.1.2') {
      // algorithm ГОСТ Р 34.10-2012/34.11-2012 with a 512-bit key
      signMethod = XmlDsigGost34102012Url512;
      signMethod = XmlDsigGost34112012Url512;
    } else if (algoId === '1.2.643.2.2.19') {
      // algorithm ГОСТ Р 34.10-2001/34.11-2001
      signMethod = XmlDsigGost3410Url;
      digestMethod = XmlDsigGost3411Url;
    } else throw new CadesError(14, 'Expected: ГОСТ Р 34.10-2012, ГОСТ Р 34.10-2001');

    if (options.base64 && !options.xml) await plugin.setProp(signedData, 'ContentEncoding', CADESCOM_BASE64_TO_BINARY);
    if (options.xml) {
      await plugin.setProp(signedData, 'SignatureType', options.signatureType);
      await plugin.setProp(signedData, 'SignatureMethod', signMethod);
      await plugin.setProp(signedData, 'DigestMethod', digestMethod);

      const encoded = await certificate.Export(CADESCOM_ENCODE_BASE64);

      data = data
        .replace('{{BST}}', encoded)
        .replace('{{SM}}', signMethod)
        .replace('{{DM}}', digestMethod);
    }

    if (!options.hash) await plugin.setProp(signedData, 'Content', data);
    else await plugin.setProp(signedData, 'Content', 'stub');

    if (options.xml) {
      if (options.signatureType === CADESCOM_XML_SIGNATURE_TYPE_TEMPLATE && options.xpath)
        signedMessage = await signedData.Sign(signer, options.xpath);
      else signedMessage = await signedData.Sign(signer);
    } else if (options.hash) {
      if (options.previousSignature) {
        try {
          await signedData.VerifyHash(data, options.previousSignature, CADESCOM_CADES_BES);
        } catch (e) {
          throw new CadesError(12, plugin.getLastError(e));
        }

        signedMessage = await signedData.CoSignHash(data, signer, CADESCOM_CADES_BES);
      } else signedMessage = await signedData.SignHash(data, signer, CADESCOM_CADES_BES);
    } else signedMessage = await signedData.SignCades(signer, CADESCOM_CADES_BES, options.detached);
  } catch (e) {
    if (e instanceof CadesError) throw e;
    else throw new CadesError(11, plugin.getLastError(e));
  }

  return signedMessage;
}

/**
 * Verifies signature.
 *
 * Throws an error if signification has been failed.
 *
 * @async
 *
 * @param {CadesPluginWrapper} plugin
 * @param {string} signedMessage
 * @param {string|object} dataToVerify
 * @param {object} options - additional options
 * @param {boolean} options.base64 - data content encoding is Base64
 * @param {boolean} options.xml - use xml object
 * @param {boolean} options.hash - sign hash instead of a document
 * @param {string} options.xpath - custom template xpath
 * @param {boolean} options.detached - generate a detached signature
 * @param {number} options.signatureType - xml signature type ({@link http://cpdn.cryptopro.ru/content/cades/namespace_c_ad_e_s_c_o_m_bc786984157586e73c5fca967d9da4ef_1bc786984157586e73c5fca967d9da4ef.html})
 * @param {string} options.signAlgorithm - expected sign algorithm
 * @param {number} options.hashAlgorithm - used hash algorithm
 * @param {number} options.certChainMode - certificate chain mode
 * @param {string} options.previousSignature - previous signature
 * @param {boolean} options.appendDate - append sign date attribute
 * @param {string} options.documentName - document name attribute
 * @param {string} options.documentDescription - document description attribute
 */
export async function verify(plugin, signedMessage, dataToVerify, options) {
  options = Object.assign({}, defaultOptions, options);

  let data;

  if (options.hash && typeof dataToVerify === 'string') {
    data = await initHashData(plugin, dataToVerify, options.hashAlgorithm, options.base64);
  } else data = dataToVerify;

  try {
    const signedData = await plugin.createObject(options.xml ? 'CAdESCOM.SignedXML' : 'CAdESCOM.CadesSignedData');

    if (options.base64 & !options.xml) await plugin.setProp(signedData, 'ContentEncoding', CADESCOM_BASE64_TO_BINARY);
    if (options.xml) await plugin.setProp(signedData, 'SignatureType', options.signatureType);

    if (!options.hash) await plugin.setProp(signedData, 'Content', data);

    if (options.xml) await signedData.Verify(signedMessage);
    else if (options.hash) await signedData.VerifyHash(data, signedMessage, CADESCOM_CADES_BES);
    else await signedData.VerifyCades(signedMessage, CADESCOM_CADES_BES, options.detached);
  } catch (err) {
    throw new CadesError(12, plugin.getLastError(err));
  }
}

/**
 * Creates and returns a verified file signature.
 *
 * Be careful, this function does not checks certificate param and does not applies default options.
 * It is highly recommended to user `sign` function instead.
 *
 * @async
 *
 * @param {CadesPluginWrapper} plugin
 * @param {object} certificate
 * @param {File} file
 * @param {object} options - additional options
 * @param {boolean} options.base64 - data content encoding is Base64
 * @param {boolean} options.xml - use xml object
 * @param {boolean} options.hash - sign hash instead of a document
 * @param {string} options.xpath - custom template xpath
 * @param {boolean} options.detached - generate a detached signature
 * @param {number} options.signatureType - xml signature type ({@link http://cpdn.cryptopro.ru/content/cades/namespace_c_ad_e_s_c_o_m_bc786984157586e73c5fca967d9da4ef_1bc786984157586e73c5fca967d9da4ef.html})
 * @param {string} options.signAlgorithm - expected sign algorithm
 * @param {number} options.hashAlgorithm - used hash algorithm
 * @param {number} options.certChainMode - certificate chain mode
 * @param {string} options.previousSignature - previous signature
 * @param {boolean} options.appendDate - append sign date attribute
 * @param {string} options.documentName - document name attribute
 * @param {string} options.documentDescription - document description attribute
 * @returns {string} - signature
 */
export async function signFile(plugin, certificate, file, options) {
  const hash = await getFileHash(plugin, file, options.hashAlgorithm);
  const sig = await createSign(plugin, certificate, hash, options);
  await verify(plugin, sig, hash, options);

  return sig;
}

/**
 * Creates and returns a verified signature.
 *
 * @async
 *
 * @param {CadesPluginWrapper} plugin
 * @param {object} certificate
 * @param {string|object|File} data
 * @param {object} options - additional options
 * @param {boolean} options.base64 - data content encoding is Base64
 * @param {boolean} options.xml - use xml object
 * @param {boolean} options.hash - sign hash instead of a document
 * @param {string} options.xpath - custom template xpath
 * @param {boolean} options.detached - generate a detached signature
 * @param {number} options.signatureType - xml signature type ({@link http://cpdn.cryptopro.ru/content/cades/namespace_c_ad_e_s_c_o_m_bc786984157586e73c5fca967d9da4ef_1bc786984157586e73c5fca967d9da4ef.html})
 * @param {string} options.signAlgorithm - expected sign algorithm
 * @param {number} options.hashAlgorithm - used hash algorithm
 * @param {number} options.certChainMode - certificate chain mode
 * @param {string} options.previousSignature - previous signature
 * @param {boolean} options.appendDate - append sign date attribute
 * @param {string} options.documentName - document name attribute
 * @param {string} options.documentDescription - document description attribute
 * @returns {string} - signature
 */
export async function sign(plugin, certificate, data, options) {
  if (!certificate) throw new CadesError(9);

  options = Object.assign({}, defaultOptions, options);

  if (options.hashAlgorithm === -1) options.hashAlgorithm = await getHashAlgorithm(plugin, certificate);

  if (data instanceof File) return await signFile(plugin, certificate, data, options);

  const sig = await createSign(plugin, certificate, data, options);
  await verify(plugin, sig, data, options);

  return sig;
}

/**
 * Searches for a certificate. Returns a certificate or `null`.
 *
 * @async
 *
 * @param {CadesPluginWrapper} plugin
 * @param {string} searchText
 * @param {number} findType - CAPICOM certificate find type (CAPICOM_CERTIFICATE_FIND_*)
 * @returns {object|null}
 */
export async function findCertificate(plugin, searchText, findType = CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME) {
  let certificate = null;

  try {
    const store = await plugin.createObject('CAdESCOM.Store');

    await store.Open(CAPICOM_CURRENT_USER_STORE, CAPICOM_MY_STORE, CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

    const certificatesObj = await store.Certificates;
    const certificates = await certificatesObj.Find(findType, searchText);
    const count = await certificates.Count;

    if (!count) return null;

    certificate = await certificates.Item(1);

    await store.Close();
  } catch (e) {
    throw new CadesError(13, searchText);
  }

  return certificate;
}

/**
 * Creates `HashedData` object from the hash string and alogrithm name.
 *
 * @async
 *
 * @param {CadesPluginWrapper} plugin
 * @param {string} hash
 * @param {number} algorithm
 * @param {boolean} base64
 * @returns {object} - hashed data
 */
export async function initHashData(plugin, hash, algorithm = CADESCOM_HASH_ALGORITHM_CP_GOST_3411, base64 = false) {
  let hashedData;

  try {
    hashedData = await plugin.createObject('CAdESCOM.HashedData');
    await plugin.setProp(hashedData, 'Algorithm', algorithm);
    if (base64) await plugin.setProp(hashedData, 'DataEncoding', CADESCOM_BASE64_TO_BINARY);

    await hashedData.SetHashValue(hash);
  } catch (e) {
    throw new CadesError(15, plugin.getLastError(e));
  }

  return hashedData;
}

/**
 * Loads file content and calculates a hash.
 *
 * @async
 *
 * @param {CadesPluginWrapper} plugin
 * @param {File} file
 * @param {number} algorithm - hash algorithm
 * @returns {object} - hashed data
 */
export async function getFileHash(plugin, file, algorithm = CADESCOM_HASH_ALGORITHM_CP_GOST_3411) {
  const header = ';base64,';
  const chunkSize = 3 * 1024 * 1024; // 3MB
  const chunks = Math.ceil(file.size / chunkSize);
  let hashedData;

  try {
    hashedData = await plugin.createObject('CAdESCOM.HashedData');
    await plugin.setProp(hashedData, 'DataEncoding', CADESCOM_BASE64_TO_BINARY);
    await plugin.setProp(hashedData, 'Algorithm', algorithm);
  } catch (e) {
    throw new CadesError(15, plugin.getLastError(e));
  }

  for (let currentChunk = 0; currentChunk < chunks; ++currentChunk) {
    const start = currentChunk * chunkSize;
    const end = start + chunkSize >= file.size ? file.size : start + chunkSize;
    const reader = new FileReader();
    const promise = new Promise((resolve, reject) => {
      reader.onload = e => resolve(e.target.result);
      reader.onerror = reject;
    });
    let fileData;

    reader.readAsDataURL(file.slice(start, end));

    try {
      fileData = await promise;
    } catch (e) {
      throw new CadesError(16, plugin.getLastError(e));
    }

    const base64Data = fileData.substr(fileData.indexOf(header) + header.length);

    try {
      await hashedData.Hash(base64Data);
    } catch (e) {
      throw new CadesError(16, plugin.getLastError(e));
    }
  }

  return hashedData;
}

/**
 * Calculates a hash of a data.
 *
 * @async
 *
 * @param {CadesPluginWrapper} plugin
 * @param {string} data
 * @param {number} algorithm - hash algorithm
 */
export async function getDataHash(plugin, data, algorithm = CADESCOM_HASH_ALGORITHM_CP_GOST_3411) {
  let hashedData;

  try {
    hashedData = await plugin.createObject('CAdESCOM.HashedData');
    await plugin.setProp(hashedData, 'DataEncoding', CADESCOM_BASE64_TO_BINARY);
    await plugin.setProp(hashedData, 'Algorithm', algorithm);
    await hashedData.Hash(data);
  } catch (e) {
    throw new CadesError(15, plugin.getLastError(e));
  }

  return hashedData;
}

export default {
  defaultOptions,
  getCertificateAlgorithm,
  getHashAlgorithm,
  createSign,
  verify,
  signFile,
  sign,
  findCertificate,
  initHashData,
  getFileHash,
  getDataHash
};
