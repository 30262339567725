/**
 * CAdESCOM encoding constants.
 *
 * @module constants/Encodings
 */

/**
 * Base64 encoding.
 *
 * @type {number}
 */
export const CADESCOM_ENCODE_BASE64 = 0;

/**
 * Binary encoding.
 *
 * @type {number}
 */
export const CADESCOM_ENCODE_BINARY = 1;

/**
 * Any encoding.
 *
 * @type {number}
 */
export const CADESCOM_ENCODE_ANY = -1;

export default {
  CADESCOM_ENCODE_BASE64,
  CADESCOM_ENCODE_BINARY,
  CADESCOM_ENCODE_ANY
};
