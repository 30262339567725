/**
 * Other ungrouped constants.
 *
 * @module constants/Other
 */

/**
 * Open the store for read/write if the user has read/write permissions.
 *
 * If there is no write permission, then open the store for reading.
 *
 * @type {number}
 */
export const CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED = 2;

/**
 * The key can be used to create a digital signature.
 *
 * @type {number}
 */
export const CAPICOM_DIGITAL_SIGNATURE_KEY_USAGE = 128;

/**
 * EKU.
 *
 * @type {number}
 */
export const CAPICOM_PROPID_ENHKEY_USAGE = 9;

export default {
  CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
  CAPICOM_DIGITAL_SIGNATURE_KEY_USAGE,
  CAPICOM_PROPID_ENHKEY_USAGE
};
