/**
 * CAdESCOM XML Signature types constants.
 *
 * @module constants/XMLSignatureTypes
 */

/**
 * Enveloped signature.
 *
 * @type {number}
 */
export const CADESCOM_XML_SIGNATURE_TYPE_ENVELOPED = 0;

/**
 * Enveloping signature.
 *
 * @type {number}
 */
export const CADESCOM_XML_SIGNATURE_TYPE_ENVELOPING = 1;

/**
 * Template.
 *
 * @type {number}
 */
export const CADESCOM_XML_SIGNATURE_TYPE_TEMPLATE = 2;

export default {
  CADESCOM_XML_SIGNATURE_TYPE_ENVELOPED,
  CADESCOM_XML_SIGNATURE_TYPE_ENVELOPING,
  CADESCOM_XML_SIGNATURE_TYPE_TEMPLATE
};
