/**
 * @module utils/CadesPluginWrapper
 */
import CadesError from '@/utils/cades-error';

/**
 * Original CAdES plugin wrapper class.
 *
 * This class provides unified async methods to create objects and set properties.
 */
export class CadesPluginWrapper {
  /**
   * Original CAdES plugin.
   *
   * @type {object}
   */
  _plugin;

  /**
   * @type {Function}
   *
   * @param {object} obj
   * @param {string} prop - property name
   * @param {*} val - property value
   * @returns {Promise<void>}
   */
  setProp;

  /**
   *
   * @param {object} plugin - original CAdES plugin instance
   */
  constructor(plugin) {
    this._plugin = plugin;

    if (!plugin.CreateObjectAsync) this.setProp = (obj, prop, val) => Promise.resolve((obj[prop] = val));
    else this.setProp = (obj, prop, val) => obj['propset_' + prop](val);
  }

  /**
   * Returns plugin module version.
   *
   * @returns {string}
   */
  get JSModuleVersion() {
    return this._plugin.JSModuleVersion;
  }

  /**
   * Raw plugin.
   *
   * @returns {object}
   */
  get plugin() {
    return this._plugin;
  }

  /**
   * @returns {boolean}
   */
  get isCapiliteEnabled() {
    return this._plugin.is_capilite_enabled;
  }

  /**
   * Returns specified constant raw value.
   *
   * It is recommented to use constants modules instead.
   *
   * @deprecated
   *
   * @param {string} name
   * @returns {*} - constant value
   */
  constant(name) {
    return this._plugin[name];
  }

  /**
   * Sets plugin object.
   *
   * @param {object} obj
   */
  set(obj) {
    return this._plugin.set(obj);
  }

  /**
   * Sets log level.
   *
   * @param {number} level
   */
  setLogLevel(level) {
    return this._plugin.set_log_level(level);
  }

  /**
   * Returns formatted error message.
   *
   * @param {Error|string} error
   * @returns {string}
   */
  getLastError(error) {
    return this._plugin.getLastError(error);
  }

  /**
   * Creates an object by the name.
   *
   * @param {string} name
   * @returns {Promise<object>}
   */
  createObject(name) {
    const plugin = this._plugin;
    return plugin.CreateObjectAsync ? plugin.CreateObjectAsync(name) : Promise.resolve(plugin.CreateObject(name));
  }
}

/**
 * Waits for the browser plugin initialization.
 *
 * @async
 *
 * @param {object} cadesplugin - original CAdES plugin instance
 * @returns {Promise<void>}
 */
export async function initCadesPlugin(cadesplugin) {
  if (!cadesplugin) throw new CadesError(0);

  try {
    await cadesplugin;
  } catch (e) {
    throw new CadesError(1, e);
  }
}

export default CadesPluginWrapper;
