/**
 * Provides an extended error class and available codes.
 *
 * @module utils/CadesError
 */

/**
 * Error codes and messages.
 *
 * Code is a message index.
 *
 * @type {Array<string}
 */
export const codes = [
  'CryptoPRO plugin not found',
  'Could not load CryptoPRO plugin',
  'Could not open a store',
  'Could not get store `Certificates` property',
  'Could not get store `Count` property',
  'Certificate enumeration failed',
  'Could not get certificate `ValidFromDate` property',
  'Could not get certificate `SubjectName` property',
  'Could not get certificate `Thumbprint` property',
  'Empty cert name (CN)',
  'Certificate not found',
  'Failed to create a signature',
  'Failed to verify a signature',
  'Could not load certificate',
  'Unsupported certificate algorithm',
  'Could not get data hash',
  'Could not get file hash',
  'File required',
  'Could not get cerificate algorithm id'
];

/**
 * Describes extended `Error` class.
 *
 * This class uses `codes` values as text messages.
 */
export class CadesError extends Error {
  /**
   * Error code.
   *
   * @type {number}
   */
  _code = 0;

  /**
   * Error reason.
   *
   * @type {Error|string|null}
   */
  _reason = null;

  /**
   * Original message text.
   *
   * @type {string}
   */
  _message = '';

  /**
   *
   * @param {number} code - error code number
   * @param {Error|string|null} reason - internal error
   */
  constructor(code, reason = null) {
    const message = codes[code];
    if (message === undefined) throw new RangeError('Invalid error code');

    let r = '';

    if (reason !== null) {
      r = reason instanceof Error ? reason.message : reason;
    }

    super('CODE: ' + code + ' ' + message + (r ? ', reason: ' + r : ''));
    Error.captureStackTrace(this, CadesError);

    this._message = message;
    this._code = code;
    this._reason = reason;
  }

  /**
   * Error code number.
   *
   * @returns {number}
   */
  get code() {
    return this._code;
  }

  /**
   * Internal error.
   *
   * @returns {Error|string|null}
   */
  get reason() {
    return this._reason;
  }

  /**
   * Reason message text.
   *
   * Function returns an empty string is reason is `null`.
   *
   * @returns {string}
   */
  get reasonMessage() {
    if (!this._reason) return '';
    else if (this._reason instanceof Error) return this._reason.message;
    else return this._reason;
  }

  /**
   * Message text.
   *
   * @return {string}
   */
  get text() {
    return this._message;
  }

  /**
   * Returns a full message text.
   *
   * @returns {string}
   */
  toString() {
    return this.message;
  }
}

export default CadesError;
