/**
 * This module provides a Base64 -> `Blob` converter.
 * @module utils/base64ToBlob
 */

/**
 * Converts Base64 string to `Blob`.
 *
 * @param {string} data - base64 data string
 * @param {string} contentType - content mime type
 * @param {number} sliceSize - slice size
 * @returns {Blob}
 */
export function base64toBlob(data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) byteNumbers[i] = slice.charCodeAt(i);

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

export default base64toBlob;
