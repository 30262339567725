<template>
  <div>
    <v-alert :value="!loaded" type="error">
      Плагин не загружен
    </v-alert>

    <v-alert :value="formattedError" type="error">
      {{ formattedError || '&nbsp;' }}
    </v-alert>

    <v-alert :value="true" type="success" v-if="sig !== ''" color="#4ad85e">
      Файл подписан
    </v-alert>

    <div v-if="loaded">
      <v-card flat>
        <v-card-text>
          <v-radio-group v-model="sourceType" row>
            <v-radio label="Файл" :value="2"></v-radio>
            <v-radio label="Текст" :value="0"></v-radio>
            <v-radio label="Хеш" :value="1"></v-radio>
          </v-radio-group>

          <div v-if="sourceType === 2">
            <upload-btn class="px-0" labelClass="upload-file-btn" title="Выберите файл" @file-update="updateFile" depressed>
            </upload-btn>
          </div>

          <upload-btn
            class="px-0"
            labelClass="upload-file-btn"
            title="Выберите файл подписи"
            @file-update="signatureFileChanged"
            depressed
          ></upload-btn>

          <div v-if="sourceType < 2">
            <v-text-field label="Имя документа" v-model="documentName" v-if="sourceType < 2"></v-text-field>
            <v-textarea label="Исходный документ" v-model="text" v-if="sourceType < 2"></v-textarea>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="my-3" flat>
        <v-card-text>
          <v-select v-model="certificate" :items="certs" :item-text="certName" return-object label="Сертификат:"></v-select>

          <div :class="certInfoClass" v-if="certificate">
            <label>Владелец:</label> {{ certInfo.owner }}<br />
            <label>Издатель:</label> {{ certInfo.issuer }}<br />
            <label>Выдан:</label> {{ certInfo.since }}<br />
            <label>Действителен до:</label> {{ certInfo.till }}<br />
            <label>Криптопровайдер:</label> {{ certInfo.provider }}<br />
            <label>Алгоритм ключа:</label> {{ certInfo.algorithm }}<br />
            <label>Статус:</label> {{ tr('certStatus[' + certInfo.status + ']') }}
          </div>
        </v-card-text>
      </v-card>

      <v-expansion-panel class="mt-3">
        <v-expansion-panel-content>
          <template v-slot:actions>
            <v-icon>settings_applications</v-icon>
          </template>
          <template v-slot:header>
            <div>Параметры подписи</div>
          </template>
          <v-card>
            <v-card-text>
              <v-select
                :items="hashAlgorithms"
                v-model="hashAlgorithm"
                :disabled="sourceType < 1"
                label="Алгоритм хэш-суммы:"
              ></v-select>

              <v-textarea label="Описание документа" v-model="documentDescription"></v-textarea>

              <v-radio-group v-model="detached">
                <v-radio label="Прикрепленная" :value="false"></v-radio>
                <v-radio label="Открепленная" :value="true"></v-radio>
              </v-radio-group>

              <v-checkbox v-model="signingTimeAttr" label="Время подписания"></v-checkbox>

              <v-checkbox v-model="isXML" label="Является документом XML"></v-checkbox>

              <v-checkbox v-model="useBase64" :disabled="isXML || !detached" label="Кодировка Base64"></v-checkbox>

              <v-select
                :items="certChainModes"
                v-model="certChainMode"
                label="Тип цепочки сертификатов, прикрепляемой к подписи:"
              ></v-select>

              <v-select :items="signatureTypes" v-model="signatureType" :disabled="!isXML" label="Тип подписи:"></v-select>

              <v-checkbox v-model="decodeToDER" label="Декодировать в DER"></v-checkbox>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-btn @click="sign" :loading="loading === true" color="#4ad85e" dark class="mx-3 my-3" v-if="sig === ''" depressed>
        Подписать
      </v-btn>
      <v-btn @click="save" color="#4ad85e" dark v-if="sig !== ''" class="mx-0 my-3" depressed>Сохранить файл подписи</v-btn>

      <!-- <section class="actions">
          <header>Подпись</header>
          <Ace
            class="editor"
            :fontSize="16"
            :showPrintMargin="true"
            :showGutter="true"
            :highlightActiveLine="true"
            :readOnly="true"
            mode="xml"
            theme="dawn"
            :value="sig"
            :onChange="sigChanged"
            name="result"
            width="auto"
            :editorProps="{ $blockScrolling: true }"
          />
        </section> -->

      <!-- <v-btn @click="(sig = ''), ($refs.previousSignature.value = '')">Очистить</v-btn> -->
    </div>
  </div>
</template>

<script>
import 'brace';
import 'brace/mode/xml';
import 'brace/mode/plain_text';
import 'brace/theme/dawn';
import { CadesPluginWrapper, initCadesPlugin } from '@/utils/cades-plugin-wrapper';
import { loadCerts, getCertInfo, certificateStatus } from '@/utils/certs-loader';
import CadesError from '@/utils/cades-error';
import { sign } from '@/utils/signification';
import base64ToBlob from '@/utils/base64-to-blob';
import { translateFactory } from '@/utils/translate';
import hashAlgorithms from '@/utils/constants/hash-algorithms';
import certChainModes from '@/utils/constants/cert-chain-modes';
import UploadButton from 'vuetify-upload-button';

const cadesplugin = window.cadesplugin;
const hashAlgorithmsList = [
  {
    text: 'Определить автоматически',
    value: -1
  },
  {
    text: 'SHA1',
    value: hashAlgorithms.CADESCOM_HASH_ALGORITHM_SHA1
  },
  {
    text: 'MD2',
    value: hashAlgorithms.CADESCOM_HASH_ALGORITHM_MD2
  },
  {
    text: 'MD4',
    value: hashAlgorithms.CADESCOM_HASH_ALGORITHM_MD4
  },
  {
    text: 'MD5',
    value: hashAlgorithms.CADESCOM_HASH_ALGORITHM_MD5
  },
  {
    text: 'SHA1 с длиной ключа 256 бит',
    value: hashAlgorithms.CADESCOM_HASH_ALGORITHM_SHA_256
  },
  {
    text: 'SHA1 с длиной ключа 384 бита',
    value: hashAlgorithms.CADESCOM_HASH_ALGORITHM_SHA_384
  },
  {
    text: 'SHA1 с длиной ключа 512 бит',
    value: hashAlgorithms.CADESCOM_HASH_ALGORITHM_SHA_512
  },
  {
    text: 'ГОСТ Р 34.11-94',
    value: hashAlgorithms.CADESCOM_HASH_ALGORITHM_CP_GOST_3411
  },
  {
    text: 'ГОСТ Р 34.11-2012 с длиной ключа 256 бит',
    value: hashAlgorithms.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256
  },
  {
    text: 'ГОСТ Р 34.11-2012 с длиной ключа 512 бит',
    value: hashAlgorithms.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512
  },
  {
    text: 'ГОСТ Р 34.11-94 HMAC',
    value: hashAlgorithms.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_HMAC
  },
  {
    text: 'ГОСТ Р 34.11-2012 HMAC с длиной ключа 256 бит',
    value: hashAlgorithms.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256_HMAC
  },
  {
    text: 'ГОСТ Р 34.11-2012 HMACс длиной ключа 512 бит',
    value: hashAlgorithms.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512_HMAC
  }
];
const certChainModesList = [
  {
    text: 'Полная, исключая корневой цертификат',
    value: certChainModes.CAPICOM_CERTIFICATE_INCLUDE_CHAIN_EXCEPT_ROOT
  },
  {
    text: 'Полная',
    value: certChainModes.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN
  },
  {
    text: 'Только конечный сертификат',
    value: certChainModes.CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY
  }
];

/**
 * This component provides a signification of documents vie CryptoPRO browser plugin and CryptoPRO CSP.
 *
 * @vuedoc
 * @exports components/Significator
 */
export default {
  name: 'Significator',

  components: {
    'upload-btn': UploadButton
  },

  async beforeMount() {
    this.loaded = false;
    this.loading = true;
    this.error = '';

    try {
      await initCadesPlugin(cadesplugin);
    } catch (e) {
      this.error = e;
      this.loading = false;
      console.error(e);

      return;
    }

    this.plugin = new CadesPluginWrapper(cadesplugin);
    this.loaded = true;

    let certs;
    try {
      certs = await loadCerts(this.plugin, this.tr);
    } catch (e) {
      this.error = e;
      this.loading = false;
      console.error(e);

      return;
    }

    this.certs = certs;
    this.loading = false;
  },

  data() {
    return {
      /**
       * @type {number}
       */
      sourceType: 2,

      /**
       * @type {Array<{text: string, value: number}>}
       */
      sourceTypes: [{ value: 0, text: 'Текст' }, { value: 1, text: 'Хэш' }, { value: 2, text: 'Файл' }],

      /**
       * @type {number}
       */
      hashAlgorithm: -1,

      /**
       * @type {Array<{text: string, value: number}>}
       */
      hashAlgorithms: hashAlgorithmsList,

      /**
       * @type {number}
       */
      certChainMode: certChainModes.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN,

      /**
       * @type {Array<{text: string, value: number}>}
       */
      certChainModes: certChainModesList,

      /**
       * @type {File}
       */
      sourceFile: null,

      /**
       * @type {File}
       */
      sourceFileSig: null,

      /**
       * @type {number}
       */
      certificateStatus,

      /**
       * @type {CadesPluginWrapper}
       */
      plugin: null,

      /**
       * @type {boolean}
       */
      loaded: false,

      /**
       * @type {boolean}
       */
      loading: false,

      /**
       * @type {boolean}
       */
      useBase64: false,

      /**
       * @type {boolean}
       */
      multipleSignatures: false,

      /**
       * @type {boolean}
       */
      isXML: false,

      /**
       * @type {boolean}
       */
      detached: true,

      /**
       * @type {number}
       */
      signatureType: 0,

      /**
       * @type {Array<string>}
       */
      signatureTypes: ['Вложенная', 'Оборачивающая', 'По шаблону'],

      /**
       * @type {string}
       */
      error: '',

      /**
       * @type {string}
       */
      text: '',

      /**
       * @type {string}
       */
      sig: '',

      /**
       * @type {boolean}
       */
      decodeToDER: true,

      /**
       * @type {string}
       */
      signatureFileName: 'signature.sig',

      /**
       * @type {boolean}
       */
      signingTimeAttr: false,

      /**
       * Custom document name (for text input).
       *
       * @type {string}
       */
      documentName: '',

      /**
       * @type {string}
       */
      documentDescription: '',

      /**
       * @type {object}
       */
      certificate: null,

      /**
       * @type {object}
       */
      certInfo: {},

      /**
       * @type {Array<object>}
       */
      certs: [],

      /**
       * @type {Function}
       */
      tr: translateFactory({
        issueDate: 'Выдан',
        codes: [
          'Не найден плагин КриптоПРО',
          'Не удалось загрузить плагин КриптоПРО',
          'Не удалось открыть хранилище',
          'Не удалось получить свойство `Certificates` хранилища',
          'Не удалось получить свойство `Count` хранилища',
          'Ошибка перебора сертификатов',
          'Не удалось получить свойство `ValidFromDate` сертификата',
          'Не удалось получить свойство `SubjectName` сертификата',
          'Не удалось получить свойство `Thumbprint` сертификата',
          'Пустое имя сертификата (CN)',
          'Сертификат не найден',
          'Возникла ошибка при создании',
          'Возникла ошибка при проверке подписи',
          'Не удалось загрузить сертификат',
          'Неподдерживаемый алгоритм сертификата',
          'Не удалось получить хэш документа',
          'Не удалось получить хэш файла',
          'Ожидается файл',
          'Не удалось получить алгоритм сертификата'
        ],
        certStatus: {
          [certificateStatus.unknown]: 'Неизвествен',
          [certificateStatus.notValidYet]: 'Срок действия не наступил',
          [certificateStatus.expired]: 'Срок действия истек',
          [certificateStatus.noBindingToPrivateKey]: 'Срок действия истек',
          [certificateStatus.chainValidationError]: 'Ошибка при проверке цепочки сертификатов',
          [certificateStatus.active]: 'Действителен'
        }
      })
    };
  },

  watch: {
    /**
     * Loads certificate info when certificate changes.
     * @async
     */
    async certificate() {
      const certificate = this.certificate;

      if (!certificate || !certificate.cert) {
        this.certInfo = {};
        return;
      }

      let info = {};

      try {
        info = await getCertInfo(certificate.cert);
      } catch (e) {
        info = {};
      }

      this.certInfo = info;
    },

    detached() {
      if (!this.detached) {
        this.useBase64 = false;
        this.multipleSignatures = false;
      }
    },

    isXML() {
      if (this.isXML) {
        this.useBase64 = false;
        this.multipleSignatures = false;
      }
    },

    sourceType() {
      if (this.sourceType !== 0) {
        this.sourceFile = null;
      }
    }
  },

  computed: {
    /**
     * User-friendly translated error string.
     *
     * @type {string}
     */
    formattedError() {
      const error = this.error;
      if (!error) return '';

      const reason = error.reasonMessage;
      return this.tr('codes[' + error.code + ']') + (reason ? ': ' + reason : '');
    },

    /**
     * Certificate status check visual indicator.
     *
     * @type {string}
     */
    certInfoClass() {
      const status = this.certInfo.status;
      let result = { 'cert-info': true };

      if (status === certificateStatus.active) result['cert-info_success'] = true;
      else if (status !== undefined) result['cert-info_error'] = true;

      return result;
    }
  },

  methods: {
    /**
     * Creates a signature of the document.
     *
     * @async
     */
    async sign() {
      this.error = '';
      this.loading = true;

      let sig;

      let dataToSign;
      let hash = false;
      let file = false;

      if (this.sourceType === 2) {
        // file
        file = true;
        hash = true;
      } else hash = this.sourceType === 1; // is source type is `hash`

      try {
        if (!this.certificate) throw new CadesError(9);

        if (file) {
          const sourceFile = this.sourceFile;

          if (!sourceFile) throw new CadesError(17);

          dataToSign = sourceFile;
          this.signatureFileName = sourceFile.name + '.sig';
        } else {
          dataToSign = this.text;
          this.signatureFileName = 'signature.sig';
        }

        const previousSignature = this.sourceFileSig;

        if (!previousSignature) {
          this.error = {
            code: 17,
            reasonMessage: 'Не выбран файл подписи'
          };
          this.loading = false;
          return;
        }

        sig = await sign(this.plugin, this.certificate.cert, dataToSign, {
          base64: this.useBase64 || file,
          xml: this.isXML,
          signatureType: this.signatureType,
          detached: this.detached,
          hash,
          hashAlgorithm: this.hashAlgorithm,
          certChainMode: this.certChainMode,
          previousSignature,
          appendDate: this.signingTimeAttr,
          documentName: this.documentName || '',
          documentDescription: this.sourceType === 2 ? this.sourceFile.name : this.documentDescription || ''
        });
      } catch (e) {
        this.error = e;
        this.loading = false;
        console.error(e);

        return;
      }

      this.loading = false;
      this.sig = sig;
    },

    textChanged(text) {
      this.text = text;
    },

    documentDescriptionChanged(text) {
      this.documentDescription = text;
    },

    sigChanged(text) {
      this.sig = text;
    },

    /**
     * Loads and decodes signature file content.
     *
     * @async
     *
     * @param {object} event - file change event
     */
    async signatureFileChanged(fileSig) {
      const file = fileSig;
      const header = ';base64,';
      const reader = new FileReader();
      const promise = new Promise((resolve, reject) => {
        reader.onload = e => resolve(e.target.result);
        reader.onerror = reject;
      });
      let fileData;

      reader.readAsDataURL(file);

      try {
        fileData = await promise;
      } catch (e) {
        console.error(e);
      }

      fileData = fileData.substr(fileData.indexOf(header) + header.length);

      const decoded = atob(fileData);
      let isBase64 = true;

      try {
        atob(decoded);
      } catch (e) {
        isBase64 = false;
      }

      if (isBase64) fileData = decoded;

      this.sourceFileSig = fileData;
    },

    /**
     * Saves a signature as a file.
     */
    save() {
      const link = document.createElement('a');
      link.download = this.signatureFileName;

      const blob = this.decodeToDER
        ? base64ToBlob(this.sig, 'application/x-pkcs7-certificates')
        : new Blob([this.sig], { type: 'application/base64' });
      link.href = window.URL.createObjectURL(blob);
      link.click();
    },

    certName(item) {
      return item.text.slice(3);
    },

    updateFile(file) {
      this.sourceFile = file;
    }
  }
};
</script>

<style>
.v-alert {
  margin-top: 20px;
}

.v-expansion-panel {
  box-shadow: none !important;
}

.actions {
  padding: 6px 8px;
  margin: 10px 0px;
}

.action {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  flex-wrap: wrap;
}

.action:last-child {
  margin-bottom: 0;
}

.upload-file-btn {
  margin-left: 0px !important;
}
</style>
