/**
 * CAPICOM certificate search types constants.
 *
 * @module constants/SearchTypes
 */

/**
 * Return certificates matching a specified SHA1 hash.
 *
 * @type {number}
 */
export const CAPICOM_CERTIFICATE_FIND_SHA1_HASH = 0;

/**
 * Return certificates whose subject name exactly or partially matches a specified subject name.
 *
 * @type {number}
 */
export const CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME = 1;

/**
 * Return certificates whose issuer name exactly or partially matches a specified issuer name.
 *
 * @type {number}
 */
export const CAPICOM_CERTIFICATE_FIND_ISSUER_NAME = 2;

/**
 * Return certificates whose root subject name exactly or partially matches a specified root subject name.
 *
 * @type {number}
 */
export const CAPICOM_CERTIFICATE_FIND_ROOT_NAME = 3;

/**
 * Return certificates whose template name matches a specified template name.
 *
 * @type {number}
 */
export const CAPICOM_CERTIFICATE_FIND_TEMPLATE_NAME = 4;

/**
 * Return certificates that have an extension that matches a specified extension.
 *
 * @type {number}
 */
export const CAPICOM_CERTIFICATE_FIND_EXTENSION = 5;

/**
 * Return certificates that have an extended property whose property identifier matches
 * a specified property identifier.
 *
 * @type {number}
 */
export const CAPICOM_CERTIFICATE_FIND_EXTENDED_PROPERTY = 6;

/**
 * Return certificates in the store that have either an enhanced key usage extension or property
 * combined with a usage identifier.
 *
 * @type {number}
 */
export const CAPICOM_CERTIFICATE_FIND_APPLICATION_POLICY = 7;

/**
 * Return certificates containing a specified policy OID.
 *
 * @type {number}
 */
export const CAPICOM_CERTIFICATE_FIND_CERTIFICATE_POLICY = 8;

/**
 * Return certificates whose time is valid.
 *
 * @type {number}
 */
export const CAPICOM_CERTIFICATE_FIND_TIME_VALID = 9;

/**
 * Return certificates whose time is not yet valid.
 *
 * @type {number}
 */
export const CAPICOM_CERTIFICATE_FIND_TIME_NOT_YET_VALID = 10;

/**
 * Return certificates whose time has expired.
 *
 * @type {number}
 */
export const CAPICOM_CERTIFICATE_FIND_TIME_EXPIRED = 11;

/**
 * Return certificates containing a key that can be used in the specified manner.
 *
 * @type {number}
 */
export const CAPICOM_CERTIFICATE_FIND_KEY_USAGE = 12;

export default {
  CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
  CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME,
  CAPICOM_CERTIFICATE_FIND_ISSUER_NAME,
  CAPICOM_CERTIFICATE_FIND_ROOT_NAME,
  CAPICOM_CERTIFICATE_FIND_TEMPLATE_NAME,
  CAPICOM_CERTIFICATE_FIND_EXTENSION,
  CAPICOM_CERTIFICATE_FIND_EXTENDED_PROPERTY,
  CAPICOM_CERTIFICATE_FIND_APPLICATION_POLICY,
  CAPICOM_CERTIFICATE_FIND_CERTIFICATE_POLICY,
  CAPICOM_CERTIFICATE_FIND_TIME_VALID,
  CAPICOM_CERTIFICATE_FIND_TIME_NOT_YET_VALID,
  CAPICOM_CERTIFICATE_FIND_TIME_EXPIRED,
  CAPICOM_CERTIFICATE_FIND_KEY_USAGE
};
