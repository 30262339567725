<template>
  <div>
    <v-tabs v-model="active">
      <v-tab>
        Подписать документ
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <Significator />
        </v-card>
      </v-tab-item>

      <v-tab>
        Добавить подпись
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <SignificatorAdd />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import Significator from '@/components/Significator.vue';
import SignificatorAdd from '@/components/SignificatorAdd.vue';

export default {
  components: {
    Significator,
    SignificatorAdd
  },

  data() {
    return {
      active: null
    };
  },
  methods: {
    next() {
      const active = parseInt(this.active);
      this.active = active < 2 ? active + 1 : 0;
    }
  }
};
</script>

<style scoped>
.theme--light.v-sheet {
  background-color: #fafafa;
}
</style>
