/**
 * XML Dsig algorithms.
 *
 * @module constants/XMLDsigAlgorithms
 */

/**
 * Obsolete URL of XML Dsig ГОСТ Р 34.10-2001.
 *
 * @type {string}
 */
export const XmlDsigGost3410UrlObsolete = 'http://www.w3.org/2001/04/xmldsig-more#gostr34102001-gostr3411';

/**
 * Obsolete URL of XML Dsig ГОСТ Р 34.11-2001.
 *
 * @type {string}
 */
export const XmlDsigGost3411UrlObsolete = 'http://www.w3.org/2001/04/xmldsig-more#gostr3411';

/**
 * URL of XML Dsig (sign method) ГОСТ Р 34.10-2001.
 *
 * @type {string}
 */
export const XmlDsigGost3410Url = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102001-gostr3411';

/**
 * URL of XML Dsig (digest method) ГОСТ Р 34.11-2001.
 *
 * @type {string}
 */
export const XmlDsigGost3411Url = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr3411';

/**
 * URL of XML Dsig (sign method) ГОСТ Р 34.10-2012 with a key length of 256 bits.
 *
 * @type {string}
 */
export const XmlDsigGost34102012Url256 = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102012-gostr34112012-256';

/**
 * URL of XML Dsig (digest method) ГОСТ Р 34.11-2012 with a key length of 256 bits.
 *
 * @type {string}
 */
export const XmlDsigGost34112012Url256 = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34112012-256';

/**
 * URL of XML Dsig (sign method) ГОСТ Р 34.10-2012 with a key length of 512 bits.
 *
 * @type {string}
 */
export const XmlDsigGost34102012Url512 = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102012-gostr34112012-512';

/**
 * URL of XML Dsig (digest method) ГОСТ Р 34.11-2012 with a key length of 512 bits.
 *
 * @type {string}
 */
export const XmlDsigGost34112012Url512 = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34112012-512';

export default {
  XmlDsigGost3410UrlObsolete,
  XmlDsigGost3411UrlObsolete,
  XmlDsigGost3410Url,
  XmlDsigGost3411Url,
  XmlDsigGost34102012Url256,
  XmlDsigGost34112012Url256,
  XmlDsigGost34102012Url512,
  XmlDsigGost34112012Url512
};
