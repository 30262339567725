/**
 * CAPICOM and CAdESCOM authentication attributes.
 *
 * @module constants/AuthenticatedAttributes
 */

/**
 * Signing time. Equals to `CADESCOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME`.
 *
 * @type {number}
 */
export const CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME = 0;

/**
 * Document name. Equals to `CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME`.
 *
 * @type {number}
 */
export const CAPICOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME = 1;

/**
 * Document description. Equals to `CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_DESCRIPTION`.
 *
 * @type {number}
 */
export const CAPICOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_DESCRIPTION = 2;

/**
 * Signing time. Equals to `CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME`.
 *
 * @type {number}
 */
export const CADESCOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME = 0;

/**
 * Document name. Equals to `CAPICOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME`.
 *
 * @type {number}
 */
export const CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME = 1;

/**
 * Document description. Equals to `CAPICOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_DESCRIPTION`.
 *
 * @type {number}
 */
export const CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_DESCRIPTION = 2;

/**
 * @type {number}
 */
export const CADESCOM_ATTRIBUTE_OTHER = -1;

export default {
  CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME,
  CAPICOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME,
  CAPICOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_DESCRIPTION,
  CADESCOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME,
  CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME,
  CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_DESCRIPTION,
  CADESCOM_ATTRIBUTE_OTHER
};
